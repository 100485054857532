/* Common settings and tools */
@import "common/01_settings/settings";
@import "common/02_tools/tools";

// COMMON

.c-header {

  &__logo {
    margin-right: 0;
  }

  &__actions {
    margin-left: 0;
  }

  &__search {
    margin: 0;
    max-width: none;

    .c-search,
    .c-search-int {
      max-width: rem(496px);
      margin: 0 auto;
    }
  }
}

.c-select-custom {
  vertical-align: middle;

  select {
    padding-right: 5px;
  }

  &::before {
    display: none;
  }
}

.c-pagination__results {
  vertical-align: middle;
}

.c-table {

  tbody tr:hover {
    box-shadow: none;
  }

  &--fixed-col {
    position: relative;
    padding-left: rem(150px);

    th:first-child,
    td:first-child {
      position: absolute;
      left: 0;
      width: rem(150px);
    }

    .c-table__subheading {
      min-height: rem(36px);
    }
  }

  .c-table__IE-wrap {
    width: 100%;
    overflow: auto;
  }
}

.c-report {

  &__main-col {
    width: calc(100vw - #{rem(200px + $o-container__gutter + $grid-gap-single)});
    transition: width 0.3s ease;

    @media (min-width: $xl) {
      width: rem($o-container - 200px);
    }

    &.is-full {
      width: calc(100vw - #{rem(24px + $o-container__gutter + $grid-gap-single)});

      @media (min-width: $xl) {
        width: rem($o-container - 24px);
      }
    }
  }
}
