// COMMON

@import "common/common-ie11";

// EINFORMA

.c-compare-table {
  &__col1 :nth-child(2n).c-compare-table__cell::before {
    margin-top: -17px;
  }
}

.c-compare-table-simple {
  &__col1 :nth-child(2n).c-compare-table-simple__cell::before {
    margin-top: -17px;
  }
}

.c-hero-small {
  overflow: hidden;

  &__img {
    height: auto;
  }
}

.c-card-report {

  &__includes {
    margin-bottom: rem(20px);
  }
}

.c-dashboard__widget {
  .o-grid {
    width: 100%;
  }
}

.c-card-av-report {

  &__price-old,
  &__price-before {
    min-height: rem(22px);
    margin: 0;
  }
}

.c-file-head {

  &__report {
    height: rem(48px);
  }
}
